   
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  scroll-behavior: smooth;
}

.homepage, .plansPage {
  background-image: url('assets/images/home/homeBg.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
a, a:hover {
  text-decoration: none;
}
.pers-copy-btn {
  background: #730f0f;
  color: #fff;
  border: none;
  border-radius: 0 6px 6px 0;
  height: 100%;
  padding: 7px 8px;
  margin: 6px 0;
}
.display-7,.Click-here {
  color: #fff;
  border: 1px solid #fff;
}
.address-text {
  color: #fff;
}
.display-7:hover,.Click-here:hover  {
  color: #fff !important;
}
tr {
  background-color: rgb(255 56 56 / 25%) !important;
}
li {
  text-align: center;
  list-style: none;
}

/* li::before {
  content: "";
  background: #fff;
  height: 4px;
  width: 4px;
  position: absolute;
  top: 13px;
  left: -11px;
  display: block;
  border-radius: 50%;
} */

li {
  position: relative;
}
a:hover{
color: #fff !important;
}

.goog-te-gadget {
  color: #6660 !important;
}
.goog-te-gadget span {
  display: none;
}

.overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.75);
  z-index: 100;
}
.modalPopup{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 101;
  padding: 20px;
  background-color: #FFF;
  text-align: center;
  border-radius: 15px;
}
.buttonsubmit
{
  background-color:#3e2026;
}
.buttoncancel
{
  background-color:#8a8383;
}
.close:before {
  content: '✕';
}
.close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding-right: 5px;
  padding-top: 5px;
}
@media all and (max-width: 767px) {
  .mobilediv{
    padding-top: 100px;
  }
}
a
{
  color: #fff !important;
}